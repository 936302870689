import React, { useState} from "react";

import '../styles/styles.css';
import '../styles/profil.css';

import imgretour from '../assets/images/back-512x512.png';
import img from '../assets/images/Logo_main_CNAM.png';
// import imgprofil from '../assets/images/profil.png';

import {Link} from 'react-router-dom';


function Profil() {


const serveur = "https://wsmobile.cnam-paysdelaloire.fr/";
const getVersionning = sessionStorage.getItem("versionning:");

    function chargerPhoto(){

    const CodeTiers = sessionStorage.getItem ("code:")
    fetch (serveur+"MobilePDL/Services/LirePhoto?intCodeTiers="+CodeTiers, {
        method: 'GET',
        headers : {
        "Content-Type": "application/json",
        "Accept": 'application/json'
    },
    })
    .then(response => {
    return response.json()
    })
    .then(data => {

        //Condition pour l'affichage de la photo + enlever lien mort + gestion affichage btn de modif photo
        if(data.existe == 0)
        {
        }
        else
        {
            const fichier = data.fichier;
            document.getElementById('profil').src = fichier;
        }
    })
}
//Lancement la fonction
{chargerPhoto()}

const code = sessionStorage.getItem ("code:");
const nom = sessionStorage.getItem ("nom:");
const prenom = sessionStorage.getItem ("prenom:");
const adresse = sessionStorage.getItem ("adresse:");
const email = sessionStorage.getItem ("email:");
const formation = sessionStorage.getItem ("formation:");

    return (
    <body className="body_profil">
        <div >
            <div className="div_entete">                    
                <Link to="/Accueil">
                    <img className="small_profil" src={imgretour} alt="logo"/>
                </Link>
            </div>
            <br/>
            <br/>
        </div>
            <div >
                <ul className="ul_profil" id="result">
                    <li className='bloc_profil' >
                        <img className="img_pp" id='profil'/>
                        <label className='label'style={{marginLeft:'100px'}}>Code Client</label>
                        <input type='texte' style={{marginBottom:'15px'}} readOnly ='readonly' className='profil' placeholder={code}></input>
                        <label className='label'>Prénom</label>
                        <input type='texte' readOnly ='readonly' className='profil' placeholder={nom}></input>
                        <label className='label'>Nom</label>
                        <input type='texte' readOnly ='readonly' className='profil' placeholder={prenom}></input>
                        <label className='label'>Adresse</label>
                        {/* <input type='texte' readOnly ='readonly' className='profil adresse' placeholder={adresse}></input> */}
                        <textarea className='texte_area_adresse' readOnly ='readonly' rows="5" cols="15">{adresse}</textarea>
                        <label className='label'>E-mail</label>
                        <input type='texte' readOnly ='readonly' className='profil' placeholder={email}></input>
                    </li>
                <div className='div_formation'>
                    <label className='label_Formation'>Formation suivi</label>
                    {/* <input type='texte' readOnly ='readonly' className='profil_formation' placeholder={formation}></input> */}
                    <textarea className='texte_area_formation' readOnly ='readonly' rows="5" cols="33">{formation}</textarea>
                </div>

                </ul>
            </div>
            <img className="img_CNAM" src={img}/>
            <footer>
                <span>{getVersionning}</span>
            </footer>
    </body>
    )
  }
export default Profil;