import {useState} from "react";
import '../styles/styles.css';
import '../styles/info_bulle.css';
import img from '../assets/images/Logo_main_CNAM.png';
import {Link, useNavigate} from 'react-router-dom';


function Login () {


  sessionStorage.setItem("versionning:","1.1.4.202210");
  
  const getVersionning = sessionStorage.getItem("versionning:");
  
  const [strEmail, setEmail] = useState("");
  const [strMotPasse, setMotPasse] = useState("");


  const serveur = "https://wsmobile.cnam-paysdelaloire.fr/";
  
  const message = sessionStorage.getItem ("message:")
  const messageRetourAccueil = sessionStorage.getItem("retourAccueil:");
  const messageRetourOublie = sessionStorage.getItem("message oublie:");


  const navigate = useNavigate();

  async function handleLogin(e)  
  {
    document.getElementById('load').style.display ='inline';
    e.preventDefault()
    let item = {strEmail,strMotPasse};
    fetch (serveur+"MobilePDL/Services/Login", {

        method: 'POST',
        headers : {
          "Content-Type": "application/json",
          "Accept": 'application/json'

      },
      body: JSON.stringify(item)
    })
    .then(response => {
      return response.json()
      
    })
    .then(data => {

      const CodeTiers = data.code
      sessionStorage.setItem ("code:",(CodeTiers))
      sessionStorage.setItem ("message:","")
      document.getElementById('load').style.display ='none';

      navigate('/Accueil')

    })

}

  return (
        <body id='corps'>
            <h1 style={{marginLeft:'-5px'}}>Bienvenue</h1>
            <span style={{marginLeft:'-20px'}} className="message">{message}</span>
            <br />
            <br />
            <span id="load" style={{border:'3px solid white',padding:'5px',display:'none', backgroundColor:'#c1002a', color: 'white', width:'250px', fontSize:'20px', marginBottom:'20px'}}>Chargement en cours...</span>
            <br />
            <br />
          <form  id="login">
            <input className="input" style={{marginLeft:'-25px'}} value={strEmail} onChange={(e) => setEmail(e.target.value)}  type="texte" placeholder="Email"/>
            {/* <a class="picto-item" href="#" aria-label="Adresse email personnelle transmise au CNAM">?</a> */}
            <br />
            <input className="input" style={{marginLeft:'-25px'}} value={strMotPasse} onChange={(e) => setMotPasse(e.target.value)} type="password" placeholder="Mot de passe"/>
            <br />
            <Link to="/Accueil">
              <input  className="input" style={{marginLeft:'-25px'}} onClick={handleLogin} type="submit" value="Me connecter" />
            </Link>
            <br />
            {/* display:'none' */}
            <br />
            <Link to="/PremiereCo" style={{marginLeft:'-20px'}} >Première connexion</Link>
            <br />
            <br />
            <Link to="/Oublie"style={{marginLeft:'-20px'}} >Mot de passe oublié</Link>
            <br />
            <br />
            <br />
            {/* <div>
              {loggedInState === "logging in" ? <ReactLoading  id='loading' type={"bubbles"} color={'#c1002a'} height={'50%'} width={'50%'} /> : ""}
            </div> */}
            <br />
            <span className="messageRetourAccueil">{messageRetourAccueil}</span>
            <span className="messageRetourAccueil">{messageRetourOublie}</span>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <img src={img} style={{marginLeft:'-15px'}} alt="logo"/>
          </form>
          <footer>
            <span>{getVersionning}</span>
          </footer>
        </body>

  )
}

export default Login;
