import {useState} from "react";
import {Link, useNavigate} from 'react-router-dom';

import '../styles/info_bulle.css';
import '../styles/styles.css';

import img from '../assets/images/Logo_main_CNAM.png';
import imgretour from '../assets/images/back-512x512.png';

function PremiereCo() {

const [strEmail, setEmail] = useState("");
const [strNom, setNom] = useState("");
const [dtNaissance, setNaissance] = useState("");

const getVersionning = sessionStorage.getItem("versionning:");
const serveur = "https://wsmobile.cnam-paysdelaloire.fr/";
const message = sessionStorage.getItem ("message premiereco:");

const navigate = useNavigate();


async function handleLogin(e)  
{
  //Gestion affichage Loading
  document.getElementById('load').style.display ='inline';

  e.preventDefault()
  let item = {strEmail,strNom,dtNaissance};
  fetch (serveur+"MobilePDL/Services/Acces", {

      method: 'POST',
      headers : {
        "Content-Type": "application/json",
        "Accept": 'application/json'

    },
    body: JSON.stringify(item)
  })
  .then(response => {
    return response.json()
  })
  .then(data => {

    const codeErreur = data.code;
    const messageReponse = data.message;

    //Switch case traitement codes erreur API 
    switch (codeErreur) 
    {
      case -2:
        sessionStorage.setItem ("retourAccueil:",(messageReponse));
        sessionStorage.setItem ("message oublie:","")
        navigate('/');
        break;

      case -5:
        sessionStorage.setItem ("retourAccueil:",(messageReponse));
        sessionStorage.setItem ("message oublie:","")
        navigate('/');
        break;

      case -6:
        sessionStorage.setItem ("retourAccueil:",(messageReponse));
        sessionStorage.setItem ("message oublie:","")
        navigate('/');
        break;

      case -4 :
        sessionStorage.setItem ("retourAccueil:",(messageReponse));
        sessionStorage.setItem ("message oublie:","")
        navigate('/');
        break;
      
      case -1 :
        sessionStorage.setItem ("message premiereco:",(messageReponse)+(codeErreur));
        break;
      
      case -3 :
        sessionStorage.setItem ("message premiereco:",(messageReponse)+(codeErreur));
        break;
      
      case -7 :
        sessionStorage.setItem ("retourAccueil:",(messageReponse)+(codeErreur));
        sessionStorage.setItem ("message oublie:","")
        navigate('/');
        break;

      case -8 :
        sessionStorage.setItem ("retourAccueil:",(messageReponse));
        sessionStorage.setItem ("message oublie:","")
        navigate('/');
        break;
    }
    document.getElementById('load').style.display ='none';
  })
}
  return (
        <body> 
          <div className="row">
            <div className="column">                    
                <Link to="/">
                    <img className="small" src={imgretour} alt="logo"/>
                </Link>
            </div>
        </div>
        <br />
        <br />
        <span id='msg' className="message">{message}</span>
        <br />
        <br />
        <form id="create" onSubmit={handleLogin}>
            <input className="input" style={{marginLeft:'10px'}} value={strEmail} onChange={(e) => setEmail(e.target.value)} required='required'  type="texte"  placeholder="Email"/>
            <a class="picto-item" href="#" aria-label="Adresse email personnelle transmis au CNAM">?</a>
            <br />
            <input value={strNom} style={{marginLeft:'-25px'}} onChange={(e) => setNom(e.target.value)} required='required' className="input"  type="texte" placeholder="Nom" id="nom"/>
            <br />
            <input value={dtNaissance} style={{marginLeft:'-25px'}} onChange={(e) => setNaissance(e.target.value)} required='required'  className="input"  type="date" placeholder="Date de Naissance" id="dateNaissance"/>
            <p style={{marginLeft:'-10px'}}>Date de naissance au format (19/05/2000)</p>
            <br />
            <input style={{marginLeft:'-10px'}} className="input" type="submit" value="Valider" />
            <br />
            <br />
            <span id="load" style={{border:'3px solid white',padding:'5px',display:'none', backgroundColor:'#c1002a', color: 'white', width:'250px', fontSize:'20px', marginBottom:'20px'}}>Chargement en cours...</span>
            <br />
            <br />
            <br />
            <br />
            <img src={img} alt="logo"/>
            <footer>
              <span>{getVersionning}</span>
            </footer>
        </form>
    </body>
    )
  }
  
  export default PremiereCo;