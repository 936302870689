import '../styles/styles.css';
import '../styles/cours.css';

import img from '../assets/images/logo_transparent-308x104.png';
import imgretour from '../assets/images/back-512x512.png';

import {Link} from 'react-router-dom';

function Cours() {
    return (
        <html>
            <div className="row">
                <div className="column">                    
                    <Link to="/Accueil">
                        <img className="small" src={imgretour} alt="logo"/>
                    </Link>
                </div>
                <div className="column"><h1>Vos Prochains Cours</h1></div>
            </div>

            <body className='body_cours'>
                <div className="tableau_cours">
                    <table className='ligne_cours'>
                        <caption className='ligne_titre'>Cours du 08/07/2022</caption>
                        <tr>
                            <th className='ligne_tableau'>9h-10h</th>
                        </tr>
                        <tr>
                            <th className='ligne_tableau'>Intégration Data</th>
                        </tr>
                        <tr>
                            <th className='ligne_tableau'>Salle 214</th>
                        </tr>
                    </table>
                    <table className='ligne_cours'>
                        <tr>
                            <th className='ligne_tableau'>10h30-12h</th>
                            <br />
                        </tr>
                        <tr>
                            <th className='ligne_tableau'>Analyse Conception</th>
                        </tr>
                        <tr>
                            <th className='ligne_tableau'>Salle 218B</th>
                        </tr>

                    </table>
                    <table className='ligne_fin_tab'>
                        <tr>
                            <th className='ligne_tableau'>14h-15h30</th>
                            <br />
                        </tr>
                        <tr>
                            <th className='ligne_tableau'>Intégration Conceptive des données sous jacente de processus</th>
                        </tr>
                        <tr>
                            <th className='ligne_tableau'>Salle 214</th>
                        </tr>
                    </table>
                </div>
            </body>
            <img className='img_cours' src={img} alt="logo"/>
        </html>
    )
  }
  
  export default Cours;