import React, { useEffect } from 'react'
import {useNavigate } from 'react-router-dom'


function PrivateRoute(props) {

const {Component} = props;

    const retourErreurAPI = sessionStorage.getItem ("code:")
    const navigate = useNavigate();
    
    useEffect(()=>{
      

      if(retourErreurAPI == null || retourErreurAPI == undefined || retourErreurAPI == -1){
        sessionStorage.setItem ("message:","Vous n'avez pas les droits.")
        navigate('/')
      }
      if(retourErreurAPI == -2){
        sessionStorage.setItem ("message:","Adresse e-mail non valide.")
        navigate('/')
      }
      if(retourErreurAPI == -3){
        sessionStorage.setItem ("message:","Probleme technique, merci de contacter le Cnam.")
        navigate('/')
      }
      if(retourErreurAPI == -4){
        sessionStorage.setItem ("message:","Mot de passe incorect")
        navigate('/')
      }
      if(retourErreurAPI == -5){
        sessionStorage.setItem ("message:","Probleme technique, merci de contacter le Cnam.")
        navigate('/')
      }
    });

return (
    <div>
        <Component />
    </div>
)

}

export default PrivateRoute
