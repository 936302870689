import React, { useState, useRef} from "react";
import {Link} from 'react-router-dom';

import '../styles/styles.css';
import '../styles/metier.css';
import '../styles/sigCanvas.css';

import SignaturePad from "react-signature-canvas";
import Popup from "reactjs-popup";
import ReactLoading from 'react-loading';

import imgretour from '../assets/images/back-512x512.png';
import imgcnam from '../assets/images/img_cnam.png';

function Metier() {
    // Constante d'état React
    const [imageURL, setImageURL] = useState(null); 
    const [isSigned, setIsSigned] = useState(false);
    const [message, setMessage] = useState("");
    const [messagePhoto, setMessagePhoto] = useState("");

    const serveur = "https://wsmobile.cnam-paysdelaloire.fr/";
    const CodeTiers = sessionStorage.getItem ("code:")
    const getVersionning = sessionStorage.getItem("versionning:");

    const nom = sessionStorage.getItem ("nom:");
    const prenom = sessionStorage.getItem ("prenom:");
    const dateNaissance = sessionStorage.getItem ("dateNaissance:");
    const validite = sessionStorage.getItem ("validite:");


    //Fonction de chargement de la signature s'effectue apres l'engistrement
    function charger(){
        fetch (serveur+"MobilePDL/Services/LireSignature?intCodeTiers="+CodeTiers, {
            method: 'GET',
            headers : {
            "Content-Type": "application/json",
            "Accept": 'application/json'
        },
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
        //Partie permetant le ciblage de l'affiche de la photo de profil
        const fichier = data.fichier
        document.getElementById('cadre_signature').src = fichier
        //Condition d'affichage du texte demandant de signez + img de la signature + gestion tbn modif signature
            if(data.existe == 0)
            {
                document.getElementById('cadre_signature').style.visibility='hidden';
                document.getElementById('div_img_modif').style.visibility='hidden';
                setMessage('Veuillez signer votre carte');   
            }
            else
            {
                document.getElementById('cadre_signature').style.visibility='';
                document.getElementById('div_img_modif').style.visibility='';
                document.getElementById('texte_sign').style.visibility='hidden';
                setMessage('');
            }
        })
    }
    //Lancement la fonction
    {charger()}

    
    function chargerPhoto(){
        //existe = 0 ou 1
            fetch (serveur+"MobilePDL/Services/LirePhoto?intCodeTiers="+CodeTiers, {
                method: 'GET',
                headers : {
                "Content-Type": "application/json",
                "Accept": 'application/json'
            },
            })
            .then(response => {
            return response.json()
            })
            .then(data => {

                //Condition pour l'affichage de la photo + enlever lien mort + gestion affichage btn de modif photo
                if(data.existe == 0)
                {
                    document.getElementById('label_dl').style.visibility='';
                    document.getElementById('elem_profil').style.visibility='hidden';
                    document.getElementById('btn_modif').style.visibility='hidden';
                    document.getElementById('profil').style.visibility='hidden';
                    setMessagePhoto('Photo manquante');
                }
                else
                {
                    const fichier = data.fichier;
                    document.getElementById('profil').src = fichier;

                    document.getElementById('label_dl').style.visibility='collapse';
                    document.getElementById('elem_profil').style.visibility='';
                    document.getElementById('profil').style.visibility='visible';
                    document.getElementById('btn_modif').style.visibility='';
                    setMessagePhoto('');
                }
            })
        }
        //Lancement la fonction
        {chargerPhoto()}
  
    //Element du composant Popup
    const sigCanvas = useRef({});
  
    //Element pour nettoyer la fenetre de signature
    const clear = () => sigCanvas.current.clear();

    //Fonction permettant l'envoi de la signature
    function envoiSignature(){

        //Stokage de la signature
        const img = document.getElementById('img_sign');

        //Précédure pour transformer l'img en dataform
        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        context.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
        var base64String = canvas.toDataURL();
        base64String = base64String.replace('data:image/png;base64,','')

        const fd = new FormData();

        fd.append("fichier",base64String);
        fd.append("auditeur",sessionStorage.getItem("code:"));

                fetch(serveur+'MobilePDL/Services/EnvoiSignature', 
                { method: 'POST'
                , 
                body: fd
                })
                
                .then(res => res.json())
            } 

        // Const permettant l'envoi et le chargement de la signature
        const save = () => {
        setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL({imageURL}));
        setTimeout(envoiSignature, 500);
        setTimeout(charger, 1000);
        };
        // Const permettant l'envoi et le chargement de la photo
        const savePhoto = () => {
        setTimeout(envoiPhoto, 500);
        setTimeout(chargerPhoto, 1000);
        };
        // Const apellé par le tbn de modif photo
        const modifPhoto = () => {
        setTimeout(ModifPhoto, 500);
        setTimeout(chargerPhoto, 1000);
        };
        //Enregistrement d'une nouvelle photo de profil
        const ModifPhoto = () => {

            var input2 = document.getElementById('file-input2');
    
            const fd = new FormData();
    
            fd.append("fichier", input2.files[0]);
            
    
            fd.append("auditeur",sessionStorage.getItem("code:"));
    
                fetch(serveur+'MobilePDL/Services/EnvoiPhoto', 
                {
                    method: 'POST', 
                    body: fd
                })
                .then(res => res.json())
            }; 

        const envoiPhoto = () => {

        var input = document.getElementById('file-input');

        const fd = new FormData();

        fd.append("fichier", input.files[0]);
        

        fd.append("auditeur",sessionStorage.getItem("code:"));

                fetch(serveur+'MobilePDL/Services/EnvoiPhoto', 
                {
                    method: 'POST', 
                    body: fd
                })
                .then(res => res.json())
        }; 

    return (
        <form className="form" type='submit'>
            <div className="row">
                <div className="column">                    
                    <Link to="/Accueil">
                        <img id='img-test' className="small" src={imgretour} alt="logo"/>
                    </Link>
                </div>
                <br />
                <br />
                <br />
                <br />
            </div>
            <div className="IMG_Metier_Recto">  
                <div className="ELEM">
                    <img className="img_profil_auditeur_recto" id='profil'/> 
                </div>
                {/* <ReactLoading id='loading' type={"bubbles"} color={'black'} height={'20%'} width={'20%'} /> */}
                    <div className="col-75">
                        <input className='input_nom_auditeur_recto'  readOnly ='readonly' type="text" placeholder={nom}></input>
                    </div>
                    <div className="col-75">
                        <input className='input_prenom_auditeur_recto' readOnly ='readonly' type="text" placeholder={prenom}></input>
                    </div>
                    <div className="col-75">
                        <input className='input_naissance_auditeur_recto' readOnly ='readonly' type="text" placeholder={dateNaissance}></input>
                    </div>
                    <div className="col-75">
                        <input className='input_validite_auditeur_recto' readOnly ='readonly' type="text" placeholder={validite}></input>
                    </div>
                    {/* Element Popup 1er partie concernant l'img rendu par le pad de signature */}
                    <Popup id='popup' modal trigger=
                        {
                        <div id ='zone_sign'className="div_signature_auditeur">
                            <img className="img_signature_auditeur" id='cadre_signature'/>
                        </div>
                        }
                        // Element concernant le pad de signature
                        closeOnDocumentClick={false}>
                        {close => (
                            <div id="signature-pad">
                                <SignaturePad  
                                ref={sigCanvas}
                                canvasProps={{
                                className: "signatureCanvasM"
                                }}
                                onEnd={() => { setIsSigned(true) }}
                                />
                                <div className="bouttons_pad">
                                    <button id='save' disabled={!isSigned}  onClick={save}>Sauvegarder</button>
                                    <button onClick={clear}>Corriger</button>
                                    <button onClick={close}>Fermer</button>
                                </div>
                            </div>
                    )}
                    </Popup>
                <br />
                <br />
                {/* Message de signature relié a une condition dans la fonction charger */}
                <p style={{color:'black',zIndex:'0',marginTop:'160px',position:"absolute",marginLeft:'80px'}} id='texte_sign'>Signez ici</p>
                {/* <ReactLoading id='loading' style={{visibility:'hidden'}} type={"bubbles"} color={'black'} height={'20%'} width={'20%'}  /> */}

                {/* Img de la signature rendu */}
                {imageURL ? (<img src={imageURL} id='img_sign' style={{ visibility: 'hidden', display: "block", margin: "0 auto", border: "1px solid black", width: "450px"}}/>) : null} 
                </div>
                {/* FIN CONTENU IMG METIER */}
                <div className="IMG_Metier_Verso">
                    <div>
                        <input className='input_organisme_verso' readOnly ='readonly' type="text" placeholder="CNAM Pays de la Loire"></input>
                    </div>
                    <div>
                        <input className='input_adresse_verso' readOnly ='readonly' type="text" placeholder="25 Bd Guy Mollet"></input>
                        <input className='input_adresse_verso_CP' readOnly ='readonly' type="text" placeholder="44300 Nantes"></input>
                    </div>
                    <div>
                        <input className='input_tel_verso' readOnly ='readonly' type="text" placeholder="02 40 16 10 10"></input>
                    </div>
                    <div>
                        <input className='input_chef_verso' readOnly ='readonly' type="text" placeholder="L.Van Asten"></input>
                    </div>
                    <img src={imgcnam} className="img_cnam" id='profil'/> 
                    {/* <div>
                        <input className='input_web_verso' type="text" placeholder=""></input>
                    </div> */}
                </div>
                    {/*  */}
                    <label id="label_dl">
                        <input className='input_modif' id="file-input" onChange={savePhoto} type="file" />
                        <span className="span_dl">Téléchargez votre photo ici</span>
                    </label>
                    <label className="elem_profil" id='elem_profil'>
                        <input  name="photo" id='file-input2' className='input_modif_auditeur' onChange={modifPhoto} type="file"/>
                        <span id='btn_modif' className="span_modif_auditeur">Modifier votre photo ici</span>
                    </label>
                <Popup id='popup' modal trigger=
                //Div encadrer forme boutton "modif signature"
                    {
                    <div id='div_img_modif'className="contenant_auditeur">
                        <img className="img_btn-modif_sign_auditeur"/>
                        <div className="texte_centrer_auditeur">Modifier votre signature ici</div>
                    </div>
                    }
                closeOnDocumentClick={false}>
                    {close => (
                        //Pad de signature apparait apres click
                        <div id="signature-pad">
                            <SignaturePad  
                            ref={sigCanvas}
                            canvasProps={{
                            className: "signatureCanvas"
                            }}
                            onEnd={() => { setIsSigned(true) }}
                            />
                            <div className="bouttons_pad">
                                <button id='save' disabled={!isSigned}  onClick={save}>Sauvegarder</button>
                                <button onClick={clear}>Corriger</button>
                                <button onClick={close}>Fermer</button>
                            </div>
                        </div>
                    )}
                </Popup>
                <div className="msg_auditeur">
                    <span className="msg_sign">{message}</span>
                    <span>{messagePhoto}</span>
                </div> 
        </form>
    )
  }
  
  export default Metier;