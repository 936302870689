import '../styles/styles.css';
import '../styles/inscription.css';

import {useState} from "react";
import {useNavigate} from 'react-router-dom';

import img from '../assets/images/logo_transparent-308x104.png';



//sessionStorage.setItem ("message inscription:","")
//sessionStorage.setItem ("retourInscription:","")


// const message = sessionStorage.getItem ("message inscription:");


function Inscription() {

const navigate = useNavigate();

const [strEmail, setEmail] = useState("");
const [strNom, setNom] = useState("");
const [strPrenom, setPrenom] = useState("");
const [strTelephone, setTelephone] = useState("");
const [strMotPasse, setMotPasse] = useState("");
const [strCivilite, setCivilite] = useState("Mr");
const [dtNaissance, setNaissance] = useState("");
const [strConfirmMotPasse, setConfirmMotPasse] = useState("");


const [messagePsw, setmessagePsw] = useState("");
const [messageCaracteres, setmessageCaracteres] = useState("");

const serveur = "https://wsmobile.cnam-paysdelaloire.fr/";
const messageRetourInscription = sessionStorage.getItem ("retourInscription:");


// function getVerificitaion(e)
// {
//   var codeResult=0;
//   if(strMotPasse.match( /[0-9]/g) && strMotPasse.match( /[A-Z]/g) && strMotPasse.match( /[^a-zA-Z\d]/g)&& strMotPasse.length >= 8)
//   {
//     setmessageCaracteres("");
//     if(strMotPasse != strConfirmMotPasse)
//     {
//       setmessagePsw('Votre mot passe de confirmation ne correspond pas au mot de passe saisi')
//     }
//     else
//     {
//       setmessagePsw('')
//       codeResult=1;
//     }
//   }
//   else
//   {
//     setmessageCaracteres("Erreur votre mot de passe ne respecte pas le format obligatoire.");
//   }
//   return codeResult;
// } 

    async function handleLogin(e)  
    {
      // if(getVerificitaion() == 1)
      // {
        // console.log('OK')
        let item = {strCivilite,strEmail,strNom,strPrenom,strTelephone,strMotPasse,dtNaissance};
        fetch (serveur+"MobilePDL/Services/Inscription", {

            method: 'POST',
            headers : {
              "Content-Type": "application/json",
              "Accept": 'application/json'
          },
          body: JSON.stringify(item)
        })
        .then(response => {
          return response.json()
        })
        .then(data => {
      
          const token = data.code
          const message = data.message
          const niveau = data.niveau

          console.log(niveau)
      
          sessionStorage.setItem ("code:",(token))
          sessionStorage.setItem ("message inscription:",(message))
  
          navigate('/')
        })  
      // }
      // else{
      //   console.log('NOK')
      //   e.preventDefault()
      // }
    }

    return (
        <body>
            <br />
            <br />
            {/* <span id='msg' className="message">{message}</span> */}
            <span className="message">{messageRetourInscription}</span>
            <br />
            <br />
            <form  id="create" onSubmit = {handleLogin} >
                <div className='div_radio'>
                    <input className="input_civilite" value="Mr" onChange={(e) => setCivilite(e.target.value)} checked={strCivilite === 'Mr'} required="required" name='Civilite' type="radio"/>
                    <label className='label_radio' for="strCiviilite">Mr</label>
                    <input className="input_civilite" value="Mme" onChange={(e) => setCivilite(e.target.value)} checked={strCivilite === 'Mme'} required="required" name='Civilite' type="radio"/>
                    <label className='label_radio' for="strCiviilite">Mme</label>
                </div>
              <br />
              <input className="input" required="required" value={strNom} onChange={(e) => setNom(e.target.value)}   type="texte" placeholder="Nom"/>
              <br />
              <input className="input" required="required" value={strPrenom} onChange={(e) => setPrenom(e.target.value)}   type="texte" placeholder="Prénom"/>
              <br />
              <input className="input" required="required" value={strEmail} onChange={(e) => setEmail(e.target.value)}   type="texte" placeholder="Email"/>
              <br />
              <input className="input" required="required" value={strTelephone} onChange={(e) => setTelephone(e.target.value)}  type="texte" placeholder="Téléphone"/>
              <br />
              <input className="input" required="required"  value={dtNaissance} onChange={(e) => setNaissance(e.target.value)}   type="date" placeholder="Date de naissance"/>
              <br />
              <p>Date de naissance au format (19/05/2000)</p>
              <br />
              <br />
              <input className="input" id="mdp" value={strMotPasse} onChange={(e) => setMotPasse(e.target.value)} required="required"  type="texte" placeholder="Mot de passe"/>
              <p id=''>Votre mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial.</p>

              <input className="input" value={strConfirmMotPasse} onChange={(e) => setConfirmMotPasse(e.target.value)} required="required" type="texte" placeholder="Confirmation de mot de passe"/>
              <br />
              <span className="message">{messageCaracteres}</span>
              <br />
              <input id='btn_submit' value="Envoyer" className="input" type="submit" />
              <br />
              <br />
              <span className="messageRetourAccueil">{messagePsw}</span>
              <br />
              <br />
              <br />
              <img src={img} alt="logo" />
            </form>
        </body>
    )
  }

  export default Inscription;