import {useState} from "react";
import {Link, useNavigate} from 'react-router-dom';

import '../styles/styles.css';

import img from '../assets/images/Logo_main_CNAM.png';
import imgretour from '../assets/images/back-512x512.png';


function Oublie() {

    const [strEmail, setEmail] = useState("");

    const getVersionning = sessionStorage.getItem("versionning:");
    const serveur = "https://wsmobile.cnam-paysdelaloire.fr/";
    // const message = sessionStorage.getItem ("message oublie:")
    
    const navigate = useNavigate();
    
    async function handleLogin(e)  
    {
      document.getElementById('load').style.display ='inline';
      e.preventDefault()
      let item = {strEmail};
      fetch (serveur+"MobilePDL/Services/Acces", {
        
          method: 'POST',
          headers : {
            "Content-Type": "application/json",
            "Accept": 'application/json'
    
        },
        body: JSON.stringify(item)
      })
      .then(response => {
        return response.json()
      })
      .then(data => {

        const code = data.code
        const message = data.message

        sessionStorage.setItem ("code:",(code))
        sessionStorage.setItem ("message oublie:",(message))
        sessionStorage.setItem ("retourAccueil:","")

        //sessionStorage.setItem ("retourAccueil:","Cet email ne correspond à aucun compte")

        document.getElementById('load').style.display ='none';
        
        navigate('/')
      })
    }

    return (
    <body>
        <div className="row">
            <div className="column">                    
                <Link to="/">
                    <img className="small" src={imgretour} alt="logo"/>
                </Link>
            </div>
        </div>
        <br />
        {/* <span id='msg' className="message">{message}</span> */}
        <br />
        <form id="create" onSubmit={handleLogin}>
          <br />
          <br />
          <input className="input" value={strEmail} onChange={(e) => setEmail(e.target.value)}  type="texte" placeholder="Email"/>
          <br />
          <input  className="input" type="submit" placeholder="Valider" />           
          <br />
          <br />
          <span id="load" style={{border:'3px solid white',padding:'5px',display:'none', backgroundColor:'#c1002a', color: 'white', width:'250px', fontSize:'20px', marginBottom:'20px'}}>Chargement en cours...</span>
          <br />
          <br />
          <br />
          <img src={img} alt="logo"/>
        </form>
        <footer>
            <span>{getVersionning}</span>
        </footer>
    </body>
    )
  }
  export default Oublie;