import React, { useState, useRef, Suspense } from "react";
import {Link} from 'react-router-dom';

import '../styles/styles.css';
import '../styles/sigCanvas.css';
import '../styles/auditeur.css';

import SignaturePad from "react-signature-canvas";
import Popup from "reactjs-popup";
import ReactLoading from 'react-loading';

import imgretour from '../assets/images/back-512x512.png';

function Auditeur() {
    const [imageURL, setImageURL] = useState(null); 
    const [isSigned, setIsSigned] = useState(false);
    const [message, setMessage] = useState("");
    const [messagePhoto, setMessagePhoto] = useState("");

    const nom = sessionStorage.getItem ("nom:");
    const prenom = sessionStorage.getItem ("prenom:");
    const dateNaissance = sessionStorage.getItem ("dateNaissance:");
    const validite = sessionStorage.getItem ("validite:");

    const serveur = "https://wsmobile.cnam-paysdelaloire.fr/";
    const CodeTiers = sessionStorage.getItem ("code:");
    const getVersionning = sessionStorage.getItem("versionning:");


    function charger(){
    //existe = 0 ou 1
        fetch (serveur+"MobilePDL/Services/LireSignature?intCodeTiers="+CodeTiers, {
            method: 'GET',
            headers : {
            "Content-Type": "application/json",
            "Accept": 'application/json'
        },
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
        const fichier = data.fichier
        document.getElementById('cadre_signature').src = fichier

            if(data.existe == 0)
            {
                document.getElementById('cadre_signature').style.visibility='hidden';
                document.getElementById('div_img_modif').style.visibility='hidden';
                setMessage('Veuillez signer votre carte');   
            }
            else
            {
                document.getElementById('cadre_signature').style.visibility='';
                document.getElementById('div_img_modif').style.visibility='';
                document.getElementById('texte_sign').style.visibility='hidden';
                setMessage('');
            }
        })
    }
    {charger()}

    function chargerPhoto(){
        //existe = 0 ou 1
            fetch (serveur+"MobilePDL/Services/LirePhoto?intCodeTiers="+CodeTiers, {
                method: 'GET',
                headers : {
                "Content-Type": "application/json",
                "Accept": 'application/json'
            },
            })
            .then(response => {
            return response.json()
            })
            .then(data => {
    
                if(data.existe == 0)
                {
                    document.getElementById('label_dl').style.visibility='';
                    document.getElementById('elem_profil').style.visibility='collapse';
                    document.getElementById('btn_modif').style.visibility='hidden';
                    document.getElementById('profil').style.visibility='hidden';
                    setMessagePhoto('Photo manquante');
                }
                else
                {
                    const fichier = data.fichier
                    document.getElementById('profil').src = fichier

                    document.getElementById('label_dl').style.visibility='collapse';
                    document.getElementById('elem_profil').style.visibility='';
                    document.getElementById('profil').style.visibility='visible';
                    document.getElementById('btn_modif').style.visibility='';
                    setMessagePhoto('');
                    
                }
            })
        }
    {chargerPhoto()}
 
    const sigCanvas = useRef({});
  
    const clear = () => sigCanvas.current.clear();

    function envoiSignature(){

        const img = document.getElementById('img_sign');

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        context.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
        var base64String = canvas.toDataURL();
        base64String = base64String.replace('data:image/png;base64,','')

        // const DataUrl = `data:image/png;base64,${imageURL}`;

        // const blob = DataURIToBlob(DataUrl);

        const fd = new FormData();

        fd.append("fichier",base64String);
        fd.append("auditeur",sessionStorage.getItem("code:"));

                fetch(serveur+'MobilePDL/Services/EnvoiSignature', 
                { method: 'POST'
                , 
                body: fd
                })
                
                .then(res => res.json())
            } 

        const save = () => {
        setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL({imageURL}));
        setTimeout(envoiSignature, 500);
        setTimeout(charger, 1000);
        };

        const savePhoto = () => {
        setTimeout(envoiPhoto, 500);
        setTimeout(chargerPhoto, 1000);
        };

        const modifPhoto = () => {
        setTimeout(ModifPhoto, 500);
        setTimeout(chargerPhoto, 1000);
        };

        const ModifPhoto = () => {

             var input2 = document.getElementById('file-input2');
    
            const fd = new FormData();
    
            fd.append("fichier", input2.files[0]);
            
    
            fd.append("auditeur",sessionStorage.getItem("code:"));
    
                fetch(serveur+'MobilePDL/Services/EnvoiPhoto', 
                {
                    method: 'POST', 
                    body: fd
                })
                .then(res => res.json())
            }; 

        const envoiPhoto = () => {

        var input = document.getElementById('file-input');

        //var files = input.files;

        const fd = new FormData();

        fd.append("fichier", input.files[0]);
        

        fd.append("auditeur",sessionStorage.getItem("code:"));

                fetch(serveur+'MobilePDL/Services/EnvoiPhoto', 
                {
                    method: 'POST', 
                    body: fd
                })
                .then(res => res.json())
        }; 

    return (
        <form className="form" type='submit'>
            <div className="row">
                <div className="column">                    
                    <Link to="/Accueil">
                        <img id='img-test' className="small" src={imgretour} alt="logo"/>
                    </Link>
                </div>
                <br />
                <br />
                <br />
                <br />
            </div>
            <div className="IMG_Auditeur_Recto">  
                <div className="ELEM">
                    <img className="img_profil" id='profil'/> 
                </div>
                {/* <ReactLoading id='loading' type={"bubbles"} color={'black'} height={'20%'} width={'20%'} /> */}
                    <div className="col-75">
                        <input className='input_nom' readOnly ='readonly' type="text" placeholder={nom}></input>
                    </div>

                    <div className="col-75">
                        <input className='input_prenom' readOnly ='readonly' type="text" placeholder={prenom}></input>
                    </div>

                    <div className="col-75">
                        <input className='input_naissance' readOnly ='readonly' type="text" placeholder={dateNaissance}></input>
                    </div>

                    <div className="col-75">
                        <input className='input_validite' readOnly ='readonly' type="text" placeholder={validite}></input>
                    </div>
                    <Popup id='popup' modal trigger=
                        {
                        <div id ='zone_sign'className="div_signature">
                            <img className="img_signature" id='cadre_signature'/>
                        </div>
                        }
                        closeOnDocumentClick={false}>
                        {close => (
                            <div id="signature-pad">
                                <SignaturePad  
                                ref={sigCanvas}
                                canvasProps={{
                                className: "signatureCanvas",
                                
                                }}
                                onEnd={() => { setIsSigned(true) }}
                                />
                                <div className="bouttons_pad">
                                    <button id='save' disabled={!isSigned}  onClick={save}>Sauvegarder</button>
                                    <button onClick={clear}>Corriger</button>
                                    <button onClick={close}>Fermer</button>
                                </div>
                            </div>
                    )}
                    </Popup>
                <br />
                <br />
                <p style={{color:'black',zIndex:'0',marginTop:'160px',position:"absolute",marginLeft:'105px'}} id='texte_sign'>Signez ici</p>
                {imageURL ? (<img src={imageURL} id='img_sign' style={{ visibility: 'hidden', display: "block", margin: "0 auto", border: "1px solid black", width: "450px"}}/>) : null} 
                </div>
                {/* FIN CONTENU IMG METIER */}
                <div className="IMG_Auditeur_Verso">
                    <div>
                        <input className='inputAuditeur_organisme_verso' readOnly ='readonly' type="text" placeholder="CNAM Pays de la Loire"></input>
                    </div>
                    <div>
                        <input className='inputAuditeur_adresse_verso' readOnly ='readonly' type="text" placeholder="25 Bd Guy Mollet"></input>
                        <input className='inputAuditeur_adresse_verso_CP' readOnly ='readonly' type="text" placeholder="44300 Nantes"></input>
                    </div>
                    <div>
                        <input className='inputAuditeur_tel_verso' readOnly ='readonly' type="text" placeholder="02 40 16 10 10"></input>
                    </div>
                    <div>
                        <input className='inputAuditeur_chef_verso' readOnly ='readonly' type="text" placeholder="L.Van Asten"></input>
                    </div>
                    {/* <div>
                        <input className='input_web_verso' type="text" placeholder=""></input>
                    </div> */}
                </div>
                <label id="label_dl">
                    <input className='input_modif' id="file-input" onChange={savePhoto} type="file" />
                    <span className="span_dl">Téléchargez votre photo ici</span>
                </label>
                <label className="elem_profil" id='elem_profil'>
                    <input id='file-input2' name="photo" className='input_modif' onChange={modifPhoto} type="file"/>
                    <span id='btn_modif' className="span_modif">Modifier votre photo ici</span>
                </label>
                <Popup id='popup' modal trigger=
                //Div encadrer forme boutton "modif signature"
                    {
                    <div id='div_img_modif'className="contenant">
                        <img className="img_btn-modif_sign"/>
                        <div className="texte_centrer">Modifier votre signature ici</div>
                    </div>
                    }
                closeOnDocumentClick={false}>
                    {close => (
                        //Pad de signature apparait apres click
                        <div id="signature-pad">
                            <SignaturePad  
                            ref={sigCanvas}
                            canvasProps={{
                            className: "signatureCanvas"
                            }}
                            onEnd={() => { setIsSigned(true) }}
                            />
                            <div className="bouttons_pad">
                                <button id='save' disabled={!isSigned}  onClick={save}>Sauvegarder</button>
                                <button onClick={clear}>Corriger</button>
                                <button onClick={close}>Fermer</button>
                            </div>
                        </div>
                    )}
                </Popup>
                <div className="msg">
                    <span>{message}</span>
                    <span>{messagePhoto}</span>
                </div>
        </form>
    )
  }
  
  export default Auditeur;