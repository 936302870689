import React from 'react';

import { Routes, Route } from "react-router-dom";

import Login from './components/Login';
import Inscription from './components/Inscription';
import Oublie from './components/Oublie';
import Accueil from './components/Accueil';
import Infos from './components/Infos';
import Campus from './components/Campus';
import Cours from './components/Cours';
import Metier from './components/Metier';
import Profil from './components/Profil';
import Auditeur from './components/Auditeur';
import PremiereCo from './components/PremiereCo';
// import Initialisation from './components/Initialisation';
import PageErreur from './private/PageErreur';


import PrivateRoute from './private/PrivateRoute';


export default function App() {

  return (
        //windows.alert("");
        <Routes>
            
            <Route path="/" element={<Login />} />

            {/* Routes Login */}
            
            <Route path="/Oublie" element={<Oublie />} />
            <Route path="/PremiereCo" element={<PremiereCo />} />
            
            {/* <Route path="/dcf11gty987" element={<Initialisation/>} /> */}
            <Route path="/q5d1q65d1c" element={<Inscription/>} />

            {/* <Route path="/Initialisation"  element={<PageErreur/>}  /> */}
            <Route path="/Inscription"  element={<PageErreur/>}  />

            <Route path="/PageErreur"  element={<PageErreur/>} />
            <Route path="/Accueil" element={<PrivateRoute Component={Accueil} />} />

            {/* Routes composants Accueils */}
            <Route path="/Infos" element={<PrivateRoute Component={Infos} />} />
            <Route path="/Campus" element={<PrivateRoute Component={Campus} />} />
            <Route path="/Cours" element={<PrivateRoute Component={Cours} />} />
            <Route path="/Auditeur" element={<PrivateRoute Component={Auditeur} />} />
            <Route path="/Profil" element={<PrivateRoute Component={Profil} />} />
            <Route path="/Metier" element={<PrivateRoute Component={Metier} />} />
            {/*----------------------------*/}

        </Routes>
    
  );
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<App />);