import {useState} from "react";
import '../styles/styles.css';
import img from '../assets/images/logo_transparent-308x104.png';
import img404 from '../assets/images/404.jpg';
import imgerreur from '../assets/images/erreur.jpg';
import {Link, useNavigate} from 'react-router-dom';



function PageErreur() {
  
    return (
          <body>
              <h1>Oups !</h1>
              <img className='imgerreur' src={imgerreur} alt="logo"/>
              <br />
              <br />
              <p>La page que vous recherchez semble introuvable.</p>
              <br />
              <p>Voici le lien de redirection vers l'accueil :</p>
              <ul>
                <Link to="/">ACCUEIL</Link>
              </ul>
              <br />
              <br />
              <br />
              <img src={img} alt="logo"/>
          </body>
    )
  }

export default PageErreur;
