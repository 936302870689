import '../styles/styles.css';

import img from '../assets/images/logo_transparent-308x104.png';
import imgretour from '../assets/images/back-512x512.png';

import {Link} from 'react-router-dom';

function Infos() {
    return (
        <body>
        <div className="row">
        <div className="column">                    
            <Link to="/Accueil">
            <img className="small" src={imgretour} alt="logo"/>
            </Link></div>
            <div className="column"><h1>Infos Pratiques</h1></div>
            <div className="column"></div>
        </div>
        <div className="result-container">
            <ul id="result">
                <li className='li_infos'>
                    <h2>Code Parking :</h2>
                    <h2>1234</h2>
                </li>
                <li className='li_infos'>
                    <h2>Horaires :</h2>
                    <h2>du Lundi au Vendredi</h2>
                    <h2>8h00 - 21h00</h2>
                    <h2>Le Samedi</h2>
                    <h2>8h00 - 13h00</h2>
                </li>
            </ul>
        </div>
        <br />
        <br />
        <br />
        <br />
        <img src={img} alt="logo"/>
    </body>
    )
  }
  
  export default Infos;