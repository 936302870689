import '../styles/styles.css';
import '../styles/campus.css';

import imgretour from '../assets/images/back-512x512.png';
import img from '../assets/images/maxresdefault.jpg';

import {Link} from 'react-router-dom';

function Campus() {
    return (
    <body>
        <div className="column">                    
        <Link to="/Accueil">
        <img className="small" src={imgretour} alt="logo"/>
        </Link>
        </div>
        <h1 className="column">Ma vie de Campus</h1>
        <div className='bloc_campus'>
            {/* <div class='cadre_img_campus'></div> */}
            <img src={img} alt="logo"/>
            <br />
            <p>Lorem ipsum</p>
        </div>
        
        
    </body>
    )
  }
  
  export default Campus;