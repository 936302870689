import '../styles/styles.css';

import img from '../assets/images/Logo_main_CNAM.png';
import imgmetier from '../assets/icons/raccourcismetier.png';
import imgdeco from '../assets/images/poweroff-128x128.png';
import imgcampus from '../assets/images/campus-life-white-512x512.png';
import imgauditeur from '../assets/icons/raccourcisauditeur.png';
import imginfos from '../assets/images/info-white-512x512.png';
import imgprofil from '../assets/icons/raccourcisprofil.png';

import {Link} from 'react-router-dom';


function Accueil() {
    const getVersionning = sessionStorage.getItem("versionning:");
    const serveur = "https://wsmobile.cnam-paysdelaloire.fr/";
    const CodeTiers = sessionStorage.getItem ("code:");

    function chargerInfos(){
    
            fetch (serveur+"MobilePDL/Services/LireAuditeur?strCodeTiers="+CodeTiers,{
                method: 'GET',
                headers : {
                "Content-Type": "application/json",
                "Accept": 'application/json'
            },
            })
            .then(response => {
            return response.json()
            })
            .then(data => {

                //Récupération des infos utilisateurs
                const nom = data.code.strNom;
                const prenom = data.code.strPrenom;
                const adresse = data.code.strAdresse;
                const email = data.code.strEmail;
                const formation = data.code.strFormation;
                const dateNaissance = data.code.strDateNaissance;
                const inscrit = data.code.intInscrit;
                const PEC = data.code.strPEC;
                const age = data.code.intAge;
                const validite = data.code.strDebut+"au"+data.code.strFin;

                sessionStorage.setItem ("nom:",(nom));
                sessionStorage.setItem ("prenom:",(prenom));
                sessionStorage.setItem ("adresse:",(adresse));
                sessionStorage.setItem ("email:",(email));
                sessionStorage.setItem ("formation:",(formation));
                sessionStorage.setItem ("dateNaissance:",(dateNaissance));
                sessionStorage.setItem ("validite:",(validite));

                // Condition Affichage Cartes
                if (inscrit == 0)
                {
                    document.getElementById('A').style.display ='none';
                    document.getElementById('M').style.display ='none';
                
                }
                else
                {
                    if(PEC == "PRO" && age <= 26 || PEC == "APP")
                    {
                        document.getElementById('A').style.visibility='visible';
                        document.getElementById('M').style.visibility='visible';
                    }
                    else
                    {
                        document.getElementById('A').style.visibility='visible';
                        document.getElementById('M').style.display ='none';
                    }
                }

            })
        }
        {chargerInfos()}

    function storage() {
        sessionStorage.clear();
        sessionStorage.setItem ("code:",-1)
        sessionStorage.setItem ("message:","Vous êtes déconnecté.")
    }

    return (
        <body>
        <br />
        <br />
        <div className="result-container">
            <ul id="result">
                                {/* <li>
                <Link to='/Cours'>
                    <img src={imgtrans} alt="logo-calendrier"/>
                </Link>
                    <h2>Mes Prochains cours</h2>
                </li> */}
                {/* <li>
                <Link to='/Campus'>
                    <img  src={imgcampus} alt="logo-campus"/>
                </Link>
                <h2>Vie de Campus</h2>
                </li> */}
                <li>
                <Link to='/Profil'>
                    <img src={imgprofil} alt="logo-calendrier"/>
                </Link>
                    <h2>Profil</h2>
                </li>
                <li id='A' >
                    <Link  to='/Auditeur'>
                        <img src={imgauditeur} alt="logo-infos"/>
                    </Link>
                    <h2>Carte Auditeur</h2>
                </li>
                <li id='M'>
                    <Link  to='/Metier'>
                        <img src={imgmetier} alt="logo-carte-auditeur"/>
                        <h2>Carte Métier</h2>
                    </Link>
                </li>
                <li id=''>
                    <Link to="/">
                        <img className='logopower' onClick={storage} src={imgdeco} alt="logo"/>
                        <h2>Déconnexion</h2>
                    </Link>
                </li>
                {/* <li>
                <Link to='/Infos'>
                    <img src={imginfos} alt="logo-infos"/>
                </Link>
                <h2>Infos Pratiques</h2>
                </li> */}
            </ul>
        </div>
        <br />
        <br />
        <br />
        <br />
        <img src={img} alt="logo"/>
        <footer>
            <span>{getVersionning}</span>
        </footer>
    </body>
    )
  }
  
  export default Accueil;